<template>
  <div
    v-intersect="onIntersect"
    class="mb-6"
  >
    <div class="ma-3 mb-6">
      <div>{{ questionSolved }}問中{{ questionCorrected }}問正解</div>
      <v-progress-linear
        height="25"
        :value="delayedLoad(totalRate)"
      >
        <template>
          <small>{{ isNaN(totalRate) ? '-' : totalRate }}%</small>
        </template>
      </v-progress-linear>
    </div>
    <v-row class="justify-space-around ma-1">
      <div
        v-for="(rate, subject) in mainSubjectProgress"
        :key="subject"
      >
        <v-row class="justify-center">
          <v-progress-circular
            :color="subjectColors[subject]"
            size="70"
            width="12"
            rotate="90"
            :value="delayedLoad(rate || 0)"
            @click="showCategoryChart(subject)"
          >
            <div class="caption">
              {{ isNaN(rate) ? '-' : rate.toFixed(1) }}%
            </div>
          </v-progress-circular>
        </v-row>
        <v-row class="justify-space-around mt-2 pa-0">
          <v-btn
            x-small="x-small"
            @click="showCategoryChart(subject)"
          >
            {{ subject }}
          </v-btn>
        </v-row>
      </div>
    </v-row>

    <v-row class="justify-space-around ma-1 mt-2">
      <div
        v-for="(rate, subject) in secondSubjectProgress"
        :key="subject"
      >
        <v-row class="justify-center">
          <v-progress-circular
            :color="subjectColors[subject]"
            size="70"
            width="12"
            rotate="90"
            :value="delayedLoad(rate || 0)"
            @click="showCategoryChart(subject)"
          >
            <div class="caption">
              {{ isNaN(rate) ? '-' : rate.toFixed(1) }}%
            </div>
          </v-progress-circular>
        </v-row>
        <v-row class="justify-space-around mt-2 pa-0">
          <v-btn
            x-small="x-small"
            @click="showCategoryChart(subject)"
          >
            {{ subject }}
          </v-btn>
        </v-row>
      </div>
    </v-row>
    <v-expand-transition>
      <v-card
        v-if="showCard"
        class="ma-3 pb-3"
      >
        <div
          v-for="(progressList, index) in [tantouCategoryProgress, ronbunCategoryProgress]"
          :key="index"
        >
          <div class="px-4 pt-3">
            {{ index === 0 ? '短答式' : '論文式' }}
          </div>
          <v-list-item
            v-for="progress in progressList"
            v-show="subjectCategoriesMap[cardSubject].includes(progress.category)"
            :key="progress.category"
          >
            <v-list-item-content
              class="py-1"
              @click="emitSelectedCategory(progress.category)"
            >
              <v-list-item-content class="pb-0 body-2">
                <v-row class="no-gutters justify-space-between">
                  <span class="col-11">{{ progress.category }}</span>
                  <v-btn
                    class="col-1"
                    icon="icon"
                    x-small="x-small"
                    @click="jumpSelection(progress.category)"
                  >
                    <v-icon>mdi-exit-to-app</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-content>
              <v-progress-linear
                height="25"
                :value="progress.rate"
              >
                <template>
                  <small>{{ isNaN(progress.rate) ? '-' : Math.ceil(progress.rate) }}%</small>
                </template>
              </v-progress-linear>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import { identifier } from "@babel/types";

export default {
  name: "ResultChart",
  props: {
    questions: { type: Array, default: () => [] }
  },
  data() {
    return {
      showCard: false,
      cardSubject: '',
      isIntersecting: false,
      firstOpen: true,
      subjectColors: {
        '財務会計論': '#004579',
        '財務会計論 (スマコア)': '#004579',
        '財務会計論 (スマコア＋)': '#004579',
        '管理会計論': '#e15b27',
        '企業法': '#ce872d',
        '監査論': '#0095da',
        '経営学': '#8BCFA8',
      },
      mainSubjects: ['財務会計論', '管理会計論', '企業法', '監査論', '経営学'],
    }
  },
  computed: {
    totalRate() {
      return Math.round(this.questionCorrected / this.questionSolved * 100)
    },
    subjects() {
      return [...new Set(this.questions.map((q) => q.level0))];
    },
    categories() {
      const categories = [...new Set(this.questions.map((q) => q.level1))]
      categories.sort((a, b) => {
        const aNumber = Number(a.replace(/^第(\d+)章.*/, '$1'))
        const bNumber = Number(b.replace(/^第(\d+)章.*/, '$1'))
        return aNumber - bNumber
      })
      return categories
    },
    subjectCategoriesMap() {
      const categoriesBySubject = this.subjects.map((subject) => {
        const subjectQuestions = this.questions.filter((q) => q.level0 === subject);
        const categories = [...new Set(subjectQuestions.map((q) => q.level1))];
        return [subject, categories];
      });
      return Object.fromEntries(categoriesBySubject)
    },
    subjectProgress() {
      const subjectRates = this.subjects.map((subject) => {
        const qs = this.questions.filter((q) => q.level0 === subject);
        const correctQsCount = qs.filter((q) => q.correct === true || q.last_answer === true).length;
        const wrongQsCount = qs.filter((q) => q.correct === false || q.last_answer === false).length;
        const correctRate = (100 * correctQsCount / (wrongQsCount + correctQsCount));
        return [subject, correctRate];
      });
      return subjectRates
    },
    mainSubjectProgress() {
      const filtered = this.subjectProgress.filter((s) => this.mainSubjects.includes(s[0]));
      return Object.fromEntries(filtered);
    },
    secondSubjectProgress() {
      const filtered = this.subjectProgress.filter((s) => !this.mainSubjects.includes(s[0]));
      return Object.fromEntries(filtered);
    },
    categoryProgress() {
      const categoryRates = this.categories.map((category) => {
        const qs = this.questions.filter((q) => q.level1 === category);
        const correctQsCount = qs.filter((q) => q.correct === true || q.last_answer === true).length;
        const wrongQsCount = qs.filter((q) => q.correct === false || q.last_answer === false).length;
        const rate = (100 * correctQsCount / (wrongQsCount + correctQsCount));
        const ronbun_flg = qs[0].ronbun_flg;
        return { category, rate, ronbun_flg };
      });
      return categoryRates
    },
    tantouCategoryProgress() {
      return this.categoryProgress.filter((c) => !c.ronbun_flg);
    },
    ronbunCategoryProgress() {
      return this.categoryProgress.filter((c) => c.ronbun_flg);
    },
    questionCorrected() {
      return this.questions.filter((q) => q.correct || q.last_answer).length;
    },
    questionSolved() {
      return this.questions.filter((q) => q.correct !== '' || q.last_answer !== null).length;
    },
  },
  methods: {
    identifier,
    showCategoryChart(subject) {
      if (subject === '' || subject === this.cardSubject) {
        this.cardSubject = '';
        this.showCard = false;
      } else {
        this.cardSubject = subject;
        this.showCard = true;
      }
    },
    onIntersect(entries) {
      // リザルトモーダルが開くのが遅いので、クリック後3秒立ってからチャートを動かすようにする
      setTimeout(() => {
        if (this.firstOpen) {
          // 初回オープン時は強制的に表示する
          this.isIntersecting = true;
          this.firstOpen = false;
        } else {
          this.isIntersecting = entries[0].isIntersecting;
        }

      }, 300)
    },
    delayedLoad(rate) {
      // ユーザがチャートを見ていればチャートにデータを入れる、見ていないときは0に設定
      return this.isIntersecting ? rate : 0;
    },
    emitSelectedCategory(category) {
      this.$emit('selectedCategory', category)
    },
    jumpSelection(category) {
      this.$router.push({ path: '/select_questions', query: { category: category } });
    }
  },
}
</script>

<style scoped>
</style>
